import React, { useState } from 'react'
// import VideoCard from '@fdn/video_card'
import ButtonMore from '@fdn/button_more'
import ErrorPlaceholder from '@fdn/error_placeholder'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'
import vars from 'Consts/vars'

const VideoDesktop = (props) => {
  const [Selectedvideo, setSelectedvideo] = useState(props.dataVideo[0])
  const [VideoList, setVideoList] = useState(
    Array.isArray(props.dataVideo) ? props.dataVideo.slice(1, 5) : [],
  )

  const clickVideo = (params) => {
    setSelectedvideo(params)
    setVideoList(props.dataVideo.filter((item) => item.id !== params.id))
  }

  return (
    <div className="video-section section">
      <div className="section-title-wrapper">
        <h3 className="section-title bordered">Videos For You</h3>
      </div>
      <div className="video-wrapper container">
        {Array.isArray(props.dataVideo) ? (
          props.dataVideo.length ? (
            <>
              <a href={Selectedvideo.link} className="video-col-big">
                {/* <VideoCard is_mobile={false} type={1} video={Selectedvideo} /> */}
                <img
                  className="btn-play"
                  src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-btn-play.svg"
                  alt="play-btn"
                />
                <ImageFallback
                  src={Selectedvideo.thumbnail.replace(
                    /0.jpg/g,
                    'maxresdefault.jpg',
                  )}
                  alt={Selectedvideo.title}
                  layout="fixed"
                  objectFit="cover"
                  width={800}
                  height={450}
                  quality={80}
                  fallbackSrc={vars.imageBrokenDefault.rectangle}
                />
              </a>
              <div className="video-col-small">
                {VideoList.map((item, idx) => (
                  <a
                    className="highlight-wrapper"
                    key={idx}
                    href={item.link}
                    // onClick={() => clickVideo(item)}
                  >
                    <div className="highlight-img">
                      <ImageFallback
                        src={item.thumbnail}
                        alt={item.title}
                        layout="fixed"
                        objectFit="cover"
                        width={156}
                        height={83}
                        quality={80}
                        fallbackSrc={vars.imageBrokenDefault.rectangle}
                      />
                    </div>
                    <p className="highlight-title">{item.title}</p>
                  </a>
                ))}
              </div>
            </>
          ) : (
            <ErrorPlaceholder
              is_mobile={props.is_mobile}
              icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/no-data-icon.svg"
              icon_alt="no data icon female daily"
              title="Sorry :("
              text="There’s no data to display"
            />
          )
        ) : (
          <ErrorPlaceholder
            is_mobile={props.is_mobile}
            icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/something-wrong-icon.svg"
            icon_alt="wrong icon female daily"
            title="Uh Oh!"
            text="Something went wrong"
          />
        )}
      </div>
      {Array.isArray(props.dataVideo) && props.dataVideo.length ? (
        <div className="btn-more-wrapper-videos-desktop">
          <ButtonMore
            text={'SEE ALL VIDEO'}
            font_family={`Josefin Sans, sans-serif`}
            font_size={16}
            font_weight={400}
            color={'#000000'}
            link={props.moreURL}
            element_id={'id_see_more_video'}
          />
        </div>
      ) : null}
      <style jsx>
        {`
          .video-section.section {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 47px 0 60px 0;
            background: #F9FAFB;
          }

          .video-wrapper {
            display: flex;
            justify-content: ${
              !Array.isArray(props.dataVideo) || !props.dataVideo.length
                ? 'center'
                : 'space-between'
            };
            margin: 0 0 10px 0;
            width: 100%;
          }

          .video-col-big {
            width: calc(68% - 16px);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .video-col-small {
            width: 31%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: space-between;
          }

          .highlight-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            
          }

          .highlight-wrapper:hover{
            cursor: pointer;
            opacity: 0.6;
          }

          .highlight-img {
            width: 42%;
            max-height: 88px;
          }

          .highlight-wrapper img {
            width: 100%;
            height: 95%;
            object-fit: cover;
          }

          .highlight-wrapper p {
            width: 55%;
            font-family: Josefin Sans, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.05em;
            text-align: left;
            color: #000000;
            margin: 0;
            height: fit-content;
          }

          .btn-more-wrapper-videos-desktop {
            margin-top: 20px;
          }
          .btn-play {
            position: absolute;
            z-index: 1;
          }
      `}
      </style>
    </div>
  )
}

export default VideoDesktop
