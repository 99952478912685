import VideoMobile from './video_mobile'
import VideoDesktop from './video_desktop'

const Video = ({ is_mobile, dataVideo }) => {
  const props = {
    is_mobile: is_mobile || false,
    dataVideo: Array.isArray(dataVideo) ? dataVideo : [],
    moreURL: process.env.NEXT_PUBLIC_ROOT_DOMAIN + '/blog/category/video-blog',
  }

  if (props.is_mobile) {
    return <VideoMobile {...props} />
  } else {
    return <VideoDesktop {...props} />
  }
}

export default Video
