import React from 'react'
import ButtonMore from '@fdn/button_more'
import Slider from 'react-slick'
// import Image from 'next/image'
import vars from 'Consts/vars'
import ImageFallback from 'Components/imagefallback'
// import VideoCard from '@fdn/video_card'

const VideoMobile = (props) => {
  const ArrowLeft = (props) => (
      <button
        {...props}
        id={`btn_prev_${props.elem}`}
        aria-label="next-slide"
      />
    ),
    ArrowRight = (props) => (
      <button
        {...props}
        id={`btn_next_${props.elem}`}
        aria-label="next-slide"
      />
    ),
    settings_dot = {
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <ArrowRight elem="video" />,
      prevArrow: <ArrowLeft elem="video" />,
    }
  return (
    <div className="video-section section">
      <div className="section-title-wrapper">
        <h3 className="section-title bordered">Videos</h3>
      </div>
      <div className="centering-element video-wrapper">
        <Slider {...settings_dot}>
          {props.dataVideo.slice(0, 4).map((item, idx) => (
            <div className="video-list-item" key={idx}>
              {/* <VideoCard is_mobile={true} video={item} /> */}
              <a href={item.link} className="highlight-img">
                <img
                  className="btn-play"
                  src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-btn-play.svg"
                  alt="play-btn"
                />
                <ImageFallback
                  src={item.thumbnail}
                  alt={item.title}
                  layout="fixed"
                  objectFit="cover"
                  width={300}
                  height={162}
                  quality={80}
                  fallbackSrc={vars.imageBrokenDefault.rectangle}
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
      <div className="btn-more-wrapper-videos-mobile">
        <ButtonMore
          text={'SEE ALL VIDEO'}
          font_family={`Josefin Sans, sans-serif`}
          font_size={16}
          font_weight={400}
          color={'#000000'}
          link={props.moreURL}
          element_id={'id_see_more_video'}
        />
      </div>
      <style jsx>
        {`
          .highlight-img {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .btn-play {
            position: absolute;
            z-index: 1;
            width: 40px;
            opacity: 0.9;
          }
          .centering-element {
            margin-left: auto;
            margin-right: auto;
          }

          .video-wrapper {
            margin: 0 0 50px;
          }

          .video-section .section-title {
            margin-bottom: 20px;
          }

          .video-wrapper .video-list-item {
            text-align: center;
          }

          .video-section .ytp-chrome-top .ytp-show-cards-title,
          .video-section .ytp-title {
            display: none !important;
          }

           {
            /* styling for slider */
          }
          
        `}
      </style>
      <style>
        {`
        .video-section .slick-next,
        .video-section .slick-prev {
          top: unset;
          bottom: -35px;
          width: 22px;
          height: 22px;
          border: 1px solid #f0f3f7;
          box-shadow: 0px 3px 10px rgba(145, 158, 171, 0.1);
          border-radius: 50%;
          z-index: 1;
        }

        .video-section .slick-prev {
          left: 0;
        }

        .video-section .slick-prev::before {
          content: '❮';
        }

        .video-section .slick-next {
          right: 0;
        }

        .video-section .slick-next::before {
          content: '❯';
        }

        .video-section .slick-next::before,
        .video-section .slick-prev::before {
          color: #000;
          font-size: 12px;
          position: absolute;
          transform: translate(-3px, -7.5px);
        }

        .btn-more-wrapper-videos-mobile {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        `}
      </style>
    </div>
  )
}

export default VideoMobile
